import Banner from "../assets/banner.png";
import { useContext, useEffect, useState } from "react";
import { CgLogIn } from "react-icons/cg";
import { FaPlus, FaRegBell } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import {
  RiCompassDiscoverLine,
  RiServerLine,
  RiShoppingCart2Line,
} from "react-icons/ri";
import { PageContext } from "../page-context";
import { BiUser } from "react-icons/bi";
import { GoGear } from "react-icons/go";
import { MdLogout } from "react-icons/md";
import axios from "axios";
import { axiosInstance } from "../util/util";

export default function Navbar() {
  const [isTabOpen, setIsTabOpen] = useState(false);

  const [userData, setUserData] = useState(null);
  const { screenSize } = useContext(PageContext);

  useEffect(() => {
    axiosInstance.get("https://lode.gg/api/landing/user").then(res => {
      setUserData(res?.data ?? null);
    }).catch(() => null);
  }, []);

  return (
    <header>
      <div>
        <div className="px-3 py-4 sm:p-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            {screenSize <= 768 ? (
              <>
                <div className="flex flex-row items-center justify-between w-full">
                  <a
                    href="/"
                    className="flex flex-row justify-center items-center"
                  >
                    <img alt="lodestone logo" src={Banner} width={150}></img>
                  </a>
                  <div>
                    {!userData ? (
                      <a
                        href="/login"
                        className="ml-[36.22px] cursor-pointer bg-[#ffab2d] transition-colors hover:bg-[#e2af63] flex flex-row justify-center items-center rounded-xl px-5 py-1 text-black"
                      >
                        <CgLogIn className="mr-2" size={17} />
                        <p className="font-bold">Sign In</p>
                      </a>
                    ) : (
                      <>
                        <div className="text-gray-300 flex flex-row items-center justify-center cursor-pointer">
                          <div
                            onClick={() => setIsTabOpen((v) => !v)}
                            className="flex flex-row items-center justify-center cursor-pointer pl-[81px]"
                          >
                            <img
                              alt="pfp of logged in user"
                              src={userData.icon_url}
                              className="rounded-full"
                              width={40}
                            />
                            <IoIosArrowDown
                              className={`transition-transform ml-1 ${isTabOpen ? "" : "-rotate-90"
                                }`}
                              size={25}
                            />
                          </div>
                          {/* profile tab */}
                          <div
                            aria-expanded={isTabOpen}
                            className={`right-6 bg-[#171717] border-[1px] border-[#363636] px-5 py-4 absolute z-10 w-fit transition-all rounded-xl ${isTabOpen ? "visible top-24" : "collapse top-20"
                              }`}
                          >
                            <div className="space-y-1">
                              <a
                                href={`/dashboard/profile`}
                                className="transition-colors px-5 py-2 rounded-xl flex flex-row items-center justify-start hover:bg-[#232323] cursor-pointer"
                              >
                                <BiUser size={20} className="mr-2" />
                                <p>Profile</p>
                              </a>
                              <a
                                href="/dashboard/notifications"
                                className="transition-colors px-5 py-2 rounded-xl flex flex-row items-center justify-start hover:bg-[#232323] cursor-pointer"
                              >
                                <FaRegBell size={20} className="mr-2" />
                                <p>Notifications</p>
                              </a>
                              <a
                                href="/dashboard/settings"
                                className="transition-colors px-5 py-2 rounded-xl flex flex-row items-center justify-start hover:bg-[#232323] cursor-pointer"
                              >
                                <GoGear size={20} className="mr-2" />
                                <p>Settings</p>
                              </a>
                              <a
                                href="/memberships"
                                className="transition-colors px-5 py-2 rounded-xl flex flex-row text-orange-400 items-center justify-start hover:bg-[#232323] cursor-pointer"
                              >
                                <RiShoppingCart2Line size={20} className="mr-2" />
                                <p>Memberships</p>
                              </a>
                            </div>
                            <div className="space-y-1">
                              <hr className="my-3 border-[#333333]"></hr>
                              <a
                                href="/events"
                                className="transition-colors px-5 py-2 rounded-xl flex flex-row items-center justify-start hover:bg-[#232323] cursor-pointer"
                              >
                                <RiCompassDiscoverLine
                                  size={20}
                                  className="mr-2"
                                />
                                <p>Apply for Events</p>
                              </a>
                              <a
                                href="/dashboard/events"
                                className="text-green-400 transition-colors px-5 py-2 rounded-xl flex flex-row items-center justify-start hover:bg-[#232323] cursor-pointer"
                              >
                                <FaPlus size={20} className="mr-2" />
                                <p>Host an Event</p>
                              </a>
                            </div>
                            <div className="space-y-1">
                              <hr className="my-3 border-[#333333]"></hr>
                              <a
                                href="/logout"
                                className="text-red-400 transition-colors px-5 py-2 rounded-xl flex flex-row items-center justify-start hover:bg-[#232323] cursor-pointer"
                              >
                                <MdLogout size={20} className="mr-2" />
                                <p>Sign Out</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div className="flex flex-row">
                    <a
                      href="/events"
                      className="flex-1 mx-[2px] cursor-pointer flex flex-row transition-colors hover:bg-[#ffffff43] hover:text-white px-4 py-2 justify-center items-center text-gray-300 rounded-2xl"
                    >
                      <RiCompassDiscoverLine size={25} className="mr-1" />
                      <p className="mx-1">Events</p>
                      <IoIosArrowDown className="ml-1" />
                    </a>
                    <a
                      href="/plugins"
                      className="flex-1 mx-[2px] cursor-pointer flex flex-row transition-colors hover:bg-[#ffffff43] hover:text-white px-4 py-2 justify-center items-center text-gray-300 rounded-2xl"
                    >
                      <RiServerLine size={25} className="mr-1" />
                      <p className="mx-1">Plugins</p>
                      <IoIosArrowDown className="ml-1" />
                    </a>
                    <a
                      href="/memberships"
                      className="flex-1 mx-[2px] cursor-pointer flex flex-row transition-colors hover:bg-[#ffffff43] hover:text-white px-4 py-2 justify-center items-center text-gray-300 rounded-2xl"
                    >
                      <RiShoppingCart2Line size={25} className="mr-1" />
                      <p className="mx-1">Tiers</p>
                      <IoIosArrowDown className="ml-1" />
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <>
                <a
                  src="/"
                  className="cursor-pointer flex flex-row justify-center items-center"
                  href="/"
                >
                  <img alt="lodestone logo" src={Banner} width={150}></img>
                </a>
                <div className="flex flex-row">
                  <a
                    href="/events"
                    className="flex-1 mx-[2px] cursor-pointer flex flex-row transition-colors hover:bg-[#ffffff43] hover:text-white px-4 py-2 justify-center items-center text-gray-300 rounded-2xl"
                  >
                    <RiCompassDiscoverLine size={25} className="mr-1" />
                    <p className="mx-1">Events</p>
                    <IoIosArrowDown className="ml-1" />
                  </a>
                  <a
                    href="/plugins"
                    className="flex-1 mx-[2px] cursor-pointer flex flex-row transition-colors hover:bg-[#ffffff43] hover:text-white px-4 py-2 justify-center items-center text-gray-300 rounded-2xl"
                  >
                    <RiServerLine size={25} className="mr-1" />
                    <p className="mx-1">Plugins</p>
                    <IoIosArrowDown className="ml-1" />
                  </a>
                  <a
                    href="/memberships"
                    className="flex-1 mx-[2px] cursor-pointer flex flex-row transition-colors hover:bg-[#ffffff43] hover:text-white px-4 py-2 justify-center items-center text-gray-300 rounded-2xl"
                  >
                    <RiShoppingCart2Line size={25} className="mr-1" />
                    <p className="mx-1">Tiers</p>
                    <IoIosArrowDown className="ml-1" />
                  </a>
                </div>

                <div>
                  {!userData ? (
                    <a
                      href="/login"
                      className="ml-[36.22px] cursor-pointer bg-[#ffab2d] transition-colors hover:bg-[#e2af63] flex flex-row justify-center items-center rounded-xl px-5 py-1 text-black"
                    >
                      <CgLogIn className="mr-2" size={17} />
                      <p className="font-bold">Sign In</p>
                    </a>
                  ) : (
                    <div className="text-gray-300 flex flex-row items-center justify-center cursor-pointer">
                      <div
                        onClick={() => setIsTabOpen((v) => !v)}
                        className="flex flex-row items-center justify-center cursor-pointer pl-[81px]"
                      >
                        <img
                          alt="pfp of logged in user"
                          src={userData.icon_url}
                          className="rounded-full"
                          width={40}
                        />
                        <IoIosArrowDown
                          className={`transition-transform ml-1 ${isTabOpen ? "" : "-rotate-90"
                            }`}
                          size={25}
                        />
                        {/* profile tab */}
                        <div
                          aria-expanded={isTabOpen}
                          className={`right-6 bg-[#171717] border-[1px] border-[#363636] px-5 py-4 absolute z-10 w-fit transition-all rounded-xl ${isTabOpen
                            ? "visible top-24 right-5"
                            : "collapse top-20 right-5"
                            }`}
                        >
                          <div className="space-y-1">
                            <a
                              href={`/dashboard/profile`}
                              className="transition-colors px-5 py-2 rounded-xl flex flex-row items-center justify-start hover:bg-[#232323] cursor-pointer"
                            >
                              <BiUser size={20} className="mr-2" />
                              <p>Profile</p>
                            </a>
                            <a
                              href="/dashboard/notifications"
                              className="transition-colors px-5 py-2 rounded-xl flex flex-row items-center justify-start hover:bg-[#232323] cursor-pointer"
                            >
                              <FaRegBell size={20} className="mr-2" />
                              <p>Notifications</p>
                            </a>
                            <a
                              href="/dashboard/settings"
                              className="transition-colors px-5 py-2 rounded-xl flex flex-row items-center justify-start hover:bg-[#232323] cursor-pointer"
                            >
                              <GoGear size={20} className="mr-2" />
                              <p>Settings</p>
                            </a>
                            <a
                              href="/memberships"
                              className="transition-colors px-5 py-2 rounded-xl flex flex-row text-orange-400 items-center justify-start hover:bg-[#232323] cursor-pointer"
                            >
                              <RiShoppingCart2Line size={20} className="mr-2" />
                              <p>Memberships</p>
                            </a>
                          </div>
                          <div className="space-y-1">
                            <hr className="my-3 border-[#333333]"></hr>
                            <a
                              href="/events"
                              className="transition-colors px-5 py-2 rounded-xl flex flex-row items-center justify-start hover:bg-[#232323] cursor-pointer"
                            >
                              <RiCompassDiscoverLine
                                size={20}
                                className="mr-2"
                              />
                              <p>Apply for Events</p>
                            </a>
                            <a
                              href="/dashboard/events"
                              className="text-green-400 transition-colors px-5 py-2 rounded-xl flex flex-row items-center justify-start hover:bg-[#232323] cursor-pointer"
                            >
                              <FaPlus size={20} className="mr-2" />
                              <p>Host an Event</p>
                            </a>
                          </div>
                          <div className="space-y-1">
                            <hr className="my-3 border-[#333333]"></hr>
                            <a
                              href="/logout"
                              className="text-red-400 transition-colors px-5 py-2 rounded-xl flex flex-row items-center justify-start hover:bg-[#232323] cursor-pointer"
                            >
                              <MdLogout size={20} className="mr-2" />
                              <p>Sign Out</p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
