import axios from "axios";
import { useEffect, useState } from "react";
import { axiosInstance, formatVersionRange, titleCase } from "../../util/util";
import { TbCube } from "react-icons/tb";
import { SiSpigotmc } from "react-icons/si";
import { LuScroll } from "react-icons/lu";
import { GrBucket } from "react-icons/gr";
import { FaRegPaperPlane } from "react-icons/fa";
import { MdClose } from "react-icons/md";

const MODRINTH_API = "https://api.modrinth.com/v2/project";

export default function EditPlugins() {

    const [pluginIps, setPluginIps] = useState({});
    const [pluginStatistics, setPluginStatistics] = useState(null);
    const [plugins, setPlugins] = useState([]);
    const [editingSession, setEditingSession] = useState(null);
    const [ips, setIps] = useState([]);
    const [comparableIps, setComparableIps] = useState([]);
    const [error, setError] = useState("");
    const [savingChanges, setSavingChanges] = useState(false);

    useEffect(() => {
        axiosInstance.get("https://lode.gg/api/landing/plugins").then((res) => {
            setPlugins(res?.data ?? []);
        }).catch(() => null);
    }, []);

    useEffect(() => {
        axiosInstance.get("https://lode.gg/api/users/plugins").then((res) => {
            setPluginIps(res?.data ?? {});
        }).catch(() => null);
    }, [pluginStatistics]);

    useEffect(() => {
        const fetchPluginData = async () => {
            const pluginDataPromises = plugins.filter(plugin => plugin.paid || plugin.has_premium).map(async (plugin) => {
                if (plugin.paid) return plugin;

                const pluginRes = await axios.get(`${MODRINTH_API}/${plugin.id}`);
                const { description, downloads, icon_url, title, wiki_url, categories, loaders } =
                    pluginRes.data;

                const versionRes = await axios.get(
                    `${MODRINTH_API}/${plugin.id}/version`
                );
                const versions = versionRes.data[0]?.game_versions || [];
                const version = versionRes.data[0]?.version_number || "Unknown";

                return {
                    has_premium: plugin.has_premium,
                    categories,
                    loaders,
                    versions,
                    description,
                    downloads,
                    icon_url,
                    title,
                    version,
                    id: plugin.id,
                    wiki_url,
                };
            });
            const pluginData = await Promise.all(pluginDataPromises);
            setPluginStatistics(pluginData.filter(Boolean));
        };

        if (plugins.length > 0) fetchPluginData();
    }, [plugins]);

    const validateIp = (ip) => {
        // Simple regex for validating IPv4 addresses
        const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipRegex.test(ip);
    };

    const addIpToWhitelist = () => {
        const value = document.getElementById("#ip-whitelist").value.trim();

        // reset value
        document.getElementById("#ip-whitelist").value = "";

        if (!value) {
            setError("Please enter an IP address.");
            return;
        }
        if (!validateIp(value)) {
            setError("Invalid IP address.");
            return;
        }
        if (ips.includes(value)) {
            setError("This IP address is already whitelisted.");
            return;
        }
        if (ips.length >= 3) {
            setError("You can only whitelist up to 3 IP addresses.");
            return;
        }

        setIps((prevIps) => [...prevIps, value]);
        setError(""); // Clear any existing error
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <h1 className="text-6xl uppercase font-heavitas-semibold">Plugin Dashboard</h1>
            <p className="text-md md:text-xl max-w-md md:max-w-3xl text-center text-gray-300 mb-10">Add server ips to the whitelist per plugin.</p>

            {/* Grid */}
            {!pluginStatistics && <p>Loading...</p>}

            {pluginStatistics && pluginStatistics.length === 0 && (
                <div className="flex flex-col items-center justify-center">
                    <h1 className="text-4xl">Looks like you haven't purchased any plugins!</h1>
                    <a href="/plugins" className="rounded-xl mt-4 text-black text-3xl px-5 py-3 bg-orange-400">Browse our Plugins</a>
                </div>
            )}

            {/* Editing Session */}
            {editingSession && (
                <div>
                    <div className="fixed w-screen h-screen top-0 left-0">
                        <div className="bg-black opacity-70 w-full h-full absolute -z-[2]"></div>
                        <MdClose className="m-5 cursor-pointer" onClick={() => setEditingSession(null)} size={45} />
                        <div className="flex flex-col items-center justify-center">
                            <div className="bg-[#292929] w-[50%] h-[50%] p-10 rounded-xl mt-24">
                                <div className="flex flex-row items-center justify-between">
                                    <div>
                                        <h1 className="text-4xl">Currently Editing: {editingSession.title}</h1>
                                        <p className="text-gray-400 mt-2">Premium Plugins require server whitelisting to prevent distribution and fraud.<br />You can only add upwards to 3 servers ips per plugin.<br />Need Help? Watch <a href="https://youtube.com/" className="underline text-orange-300">our video</a> on how to grab your server's ip.</p>
                                    </div>
                                    <img src={editingSession.icon_url} alt="icon" width={100} className="rounded-xl bg-[#343434]" />
                                </div>
                                <div>
                                    <div className="flex flex-row justify-between items-center mt-5">
                                        <input id="#ip-whitelist" placeholder="Add a server IP to whitelist" className="w-full px-5 py-2 rounded-xl bg-[#343434] mr-2"></input>
                                        <button onClick={addIpToWhitelist} className="bg-[#343434] px-5 py-2 rounded-xl w-[200px]">Add to Whitelist</button>
                                    </div>

                                    {/* Display error if IP is invalid */}
                                    {error && <p className="text-red-500 mt-2">{error}</p>}

                                    <div className="flex flex-row items-center justify-between mt-5">
                                        <div className="flex flex-col w-full space-y-2">
                                            <h1 className="text-2xl">Whitelisted Servers</h1>
                                            {ips.map((ip, index) => (
                                                <div key={index + ip} className="w-full px-5 py-2 flex flex-row items-center justify-between rounded-xl bg-[#343434] mr-2">
                                                    <p>{ip}</p>
                                                    <MdClose onClick={() => setIps((prevIps) => prevIps.filter((i) => i !== ip))} size={25} className="cursor-pointer" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {comparableIps != ips && (
                                        <button
                                            onClick={() => {
                                                setSavingChanges(true);

                                                // post to axios
                                                axiosInstance.post(`https://lode.gg/api/users/plugins/${editingSession.id}/update`, { ips })
                                                    .then(({ data }) => {
                                                        setSavingChanges(false);

                                                        if (data.error) {
                                                            setError(data.error);
                                                        } else {
                                                            setIps([]);
                                                            setEditingSession(null);
                                                            window.location.reload();
                                                        }
                                                    }).catch(() => {
                                                        setError("Our API is currently down. Please try again later.");
                                                        setSavingChanges(false)
                                                    });
                                            }}
                                            className={`relative right-0 mt-5 text-black px-5 py-2 rounded-xl w-[200px] ${savingChanges ? "cursor-not-allowed bg-gray-400" : "bg-green-400"}`}>Save Changes</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="my-2 w-[24rem] md:w-[52rem] lg:w-[80rem] lg:grid-cols-3 md:grid-cols-2 grid grid-cols-1">
                {pluginStatistics && pluginStatistics
                    .filter(plugin => plugin.paid ? pluginIps[plugin.id] : plugin.has_premium)
                    .map((plugin, index) => (
                        <div key={index + plugin.id} className="rounded-xl bg-[#292929] px-5 py-5 mx-1 my-1">
                            <a className="flex flex-row items-center">
                                <img alt="logo" src={plugin.icon_url} width={85} className="rounded-xl bg-[#343434]" />
                                <div className="ml-4">
                                    <h1 className="text-2xl">{plugin.title}</h1>
                                    <p className="text-gray-300 text-md">{plugin.version}</p>
                                    <p className="text-gray-300 text-md">{formatVersionRange(plugin.versions)}</p>
                                </div>
                            </a>
                            <div className="items-center justify-start text-gray-300 flex flex-row mt-3">
                                {(plugin.categories ?? []).map((category, index) => (
                                    <p key={index + category} className="bg-[#434343] px-3 py-1 rounded-2xl mr-1 text-[12px]">{titleCase(category)}</p>
                                ))}
                            </div>
                            <div className="items-center justify-start text-gray-300 flex flex-row mt-3">
                                {(plugin.loaders ?? []).map((loader, index) => {
                                    switch (loader.toUpperCase()) {
                                        case "PAPER":
                                            return <div key={loader + index} className="items-center justify-center flex flex-row text-[#eaa] bg-[#434343] px-3 py-1 rounded-2xl mr-1 text-[12px]"><FaRegPaperPlane size={15} className="mr-2" /><p>{titleCase(loader)}</p></div>;
                                        case "BUKKIT":
                                            return <div key={loader + index} className="items-center justify-center flex flex-row text-[#f6af7b] bg-[#434343] px-3 py-1 rounded-2xl mr-1 text-[12px]"><GrBucket size={15} className="mr-2" /><p>{titleCase(loader)}</p></div>;
                                        case "FABRIC":
                                            return <div key={loader + index} className="items-center justify-center flex flex-row text-[#dbb69b] bg-[#434343] px-3 py-1 rounded-2xl mr-1 text-[12px]"><LuScroll size={15} className="mr-2" /><p>{titleCase(loader)}</p></div>;
                                        case "SPIGOT":
                                            return <div key={loader + index} className="items-center justify-center flex flex-row text-[#f1cc84] bg-[#434343] px-3 py-1 rounded-2xl mr-1 text-[12px]"><SiSpigotmc size={15} className="mr-2" /><p>{titleCase(loader)}</p></div>;
                                        case "PURPUR":
                                            return <div key={loader + index} className="items-center justify-center flex flex-row text-[#c3abf7] bg-[#434343] px-3 py-1 rounded-2xl mr-1 text-[12px]"><TbCube size={15} className="mr-2" /><p>{titleCase(loader)}</p></div>;
                                        default:
                                            return null;
                                    }
                                })}
                            </div>
                            <div className="mt-2">
                                <p className="text-gray-300 h-28">{plugin.description}</p>
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={`https://lode.gg/dashboard/${plugin.id}/download`}
                                    className="font-extrabold text-black bg-green-400 px-3 py-2 rounded-xl flex items-center justify-center mt-4"
                                >
                                    Download
                                </a>
                                {plugin.wiki_url && (
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={plugin.wiki_url}
                                        className="font-extrabold text-black bg-orange-400 px-3 py-2 rounded-xl flex items-center justify-center mt-2"
                                    >
                                        Read Documentation
                                    </a>
                                )}
                                <a
                                    onClick={() => {
                                        setIps(pluginIps[plugin.id] ?? []);
                                        setComparableIps(pluginIps[plugin.id] ?? []);
                                        setEditingSession({ title: plugin.title, id: plugin.id, icon_url: plugin.icon_url });
                                    }}
                                    className="cursor-pointer font-extrabold text-black bg-red-400 px-3 py-2 rounded-xl flex items-center justify-center mt-2"
                                >
                                    Edit Settings
                                </a>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}
